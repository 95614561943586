
      @import "regions/kursk/styles/themes/sakhalin/vars.scss";
      @import "regions/kursk/styles/common_vars.scss";
      @import "regions/kursk/styles/themes/sakhalin/fonts.scss";
      @import "regions/kursk/styles/themes/sakhalin/typography.scss";
      @import "regions/kursk/styles/text.scss";
    
.button {
  display: flex;
  width: 100%;
  height: 40px;
  justify-content: center;
  align-items: center;
  text-align: center;
  cursor: pointer;
  padding: 7px 24px;
  transition: 0.3s;

  .text {
    @include b3;

    color: $button-text-action-default;
    text-align: center;
    text-transform: uppercase;
    white-space: nowrap;
  }
}

.button.greenPrimary {
  background-color: $button-bg-default;

  &[disabled] {
    cursor: initial;
    background-color: $button-bg-disabled;

    .text {
      color: $white;
    }

    &:hover {
      background-color: $button-bg-disabled;

      .text {
        color: $white;
      }
    }
  }

  &:hover {
    background-color: $button-bg-hover;
  }

  .text {
    color: $button-text-action-default;
  }
}

.button.greenPrimarySearch {
  background-color: $button-bg-default;

  &[disabled] {
    cursor: initial;
  }

  &:hover {
    background-color: $button-bg-hover;
  }

  .text {
    color: $button-text-action-default;
  }
}

.button.vkButton {
  background-color: $button-vk-bg-default;

  &[disabled] {
    cursor: initial;
    background-color: $button-bg-disabled;

    .text {
      color: $white;
    }

    &:hover {
      background-color: $button-bg-disabled;
    }
  }

  &:hover {
    background-color: $button-vk-bg-hover;
  }

  .text {
    color: $white;
  }
}

.button.ghostButton {
  border: 1px solid $primary;
  background-color: $white;

  &[disabled] {
    cursor: initial;
    border: 1px solid $primary-disabled;
    background-color: $white;

    .text {
      color: $primary-disabled;
    }

    &:hover {
      border-color: $primary-disabled;

      .text {
        color: $primary-disabled;
      }
    }
  }

  &:hover {
    border-color: $primary-hover;

    .text {
      color: $primary-hover;
    }
  }

  &:active {
    border: 1px solid $black;
    background-color: $white;

    .text {
      color: $black;
    }
  }

  .text {
    color: $primary;
  }
}

.button.esiaButton {
  border: 1px solid #0d4cd3;
  background: #fff;

  .text {
    color: $white;
  }

  &:hover {
    background: #edf2fe;
  }

  &[disabled] {
    cursor: initial;
    border: 1px solid $button-bg-disabled;
    background-color: $button-bg-disabled;

    &:hover {
      background: $button-bg-disabled;
    }
  }
}
