:global(body.region__bel) {

  .header {
    text-align: center;
    color: $black;
    font-size: 24px;
    font-weight: 500;
    line-height: 33px;
    margin-bottom: 10px;

    @include breakpoint($sm) {
      font-size: 20px;
      line-height: 24px;
      margin-bottom: 10px;
      padding-bottom: 10px;
      padding-top: 5px;
    }
  }

  .button {
    color: $white;
    display: block;
    margin-bottom: 20px;
    width: 100%;
    font-size: 12px;
    font-weight: 400;
    line-height: 26px;

    &.secondary {
      background-color: #fff;
      border: 1px solid $primary;
      color: $primary;
    }

    &.disabled {
      background-color: $button-bg-disabled;
      color: $white;
    }
  }
}
