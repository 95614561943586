:global(body.region__bel) {

  .button {
    @include b3;

    color: $white;
    padding: 0 20px;
  }

  .input_wrapper {

    input {

      &:focus {
        border-color: $primary;
      }
    }
  }
}
