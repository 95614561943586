
      @import "regions/kursk/styles/themes/sakhalin/vars.scss";
      @import "regions/kursk/styles/common_vars.scss";
      @import "regions/kursk/styles/themes/sakhalin/fonts.scss";
      @import "regions/kursk/styles/themes/sakhalin/typography.scss";
      @import "regions/kursk/styles/text.scss";
    
@import 'BaseSubscribeForm.bel.module';

.wrapper {
  display: flex;
  position: relative;
  flex-direction: row;
  justify-content: space-between;
  border: 1px solid $subscribe-form-border;
  padding: 40px;
  background-color: $subscribe-form-bg;

  @include breakpoint($xl) {
    height: 400px;
  }
}

.form {
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  margin-bottom: 80px;
  width: 60%;
  flex: 1 0;

  @include breakpoint($sm) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.title {
  @include h2;

  text-transform: uppercase;
  margin-bottom: 4px;

  @include breakpoint($sm) {
    @include h5;
  }
}

.subTitle {
  @include t1;

  margin-bottom: 40px;

  @include breakpoint($sm) {
    @include p3;

    margin-bottom: 20px;
  }
}

.emailLabel {
  @include t4;

  display: block;
  opacity: 0.6;
  margin-bottom: 10px;
}

.inputWrapper {
  display: flex;
  flex-direction: column;
}

.checkboxWrapper {
  @include breakpoint($sm) {
    @include gap(50px);
  }

  &__output {
    margin-top: 10px;
    margin-bottom: 40px;
  }

  &__phone {
    margin-bottom: 30px;
  }
}

.agreementText {
  @include t4;

  opacity: 0.6;
}

.inputRow {
  display: flex;
  flex-direction: row;
  width: 100%;

  @include gap(12px);
}

.input {
  display: flex;
  flex: 1;

  @include breakpoint($xl-and-md) {
    max-width: 420px;
    margin-right: 20px;
  }
}

.subscribed {
  @include t1;

  font-weight: 500;
}

.logo {
  position: absolute;
  right: 0;
  bottom: 0;

  @include breakpoint($md-and-sm) {
    display: none;
  }

  img {
    vertical-align: bottom;
  }
}

.desktopButton {
  @include breakpoint($sm) {
    display: none;
  }
}

.mobileButton {
  @include breakpoint($xl-and-md) {
    display: none;
  }
}

.modal {
  padding: 40px;
  background-color: $white;

  @include breakpoint($sm) {
    padding: 24px 20px 40px;
  }

  .wrapper {
    border: none;
    height: auto;
    padding: 0;
    display: flex;
    flex-direction: column;
    background-color: transparent;
  }

  .form {
    margin-top: 0;
    margin-bottom: 0;
    width: 100%;
  }

  .desktopButton {
    display: none;
  }

  .mobileButton {
    display: block;
    width: 100%;
    margin-top: 40px;
  }

  .input {
    max-width: unset;
    margin-right: 0;
  }

  .title {
    margin-bottom: 16px;

    @include breakpoint($sm) {
      @include h2;

      margin-bottom: 8px;
    }
  }

  .subTitle {
    margin-bottom: 24px;

    @include breakpoint($sm) {
      @include t3;

      margin-bottom: 12px;
    }

    &_noMargin {
      margin-bottom: 0;
    }
  }

  .checkboxWrapper {
    @include breakpoint($sm) {
      @include gap(0);
    }
  }

  .textCentered {
    text-align: center;
  }
}
