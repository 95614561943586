
      @import "regions/kursk/styles/themes/sakhalin/vars.scss";
      @import "regions/kursk/styles/common_vars.scss";
      @import "regions/kursk/styles/themes/sakhalin/fonts.scss";
      @import "regions/kursk/styles/themes/sakhalin/typography.scss";
      @import "regions/kursk/styles/text.scss";
    
.hidden {
  display: none;
}

.dropdownList {
  position: absolute;
  right: calc(50% - 600px);
  width: 120px;
  top: 70px;
  padding: 20px;
  z-index: 100;
  background-color: $white;
  box-shadow: 0 2px 25px rgb(0 0 0 / 15%);

  @include breakpoint($md-and-sm) {
    right: 0;
  }

  ul {
    display: flex;
    flex-direction: column;
    gap: 14px;

    li {
      @include t4;

      color: $black;
      cursor: pointer;

      a {
        color: $black;
      }
    }

    li:hover {
      color: $primary;

      button {
        color: $primary;
      }

      a {
        color: $primary;
      }
    }
  }

  &__profile {

    ul {

      li {

        a,
        button {
          @include t3;
        }
      }
    }
  }
}

.header.header_prepare {
  position: fixed;
  transform: translateY(-110px);
}

.header.header_hide {
  position: fixed;
  transform: translateY(-110px);
  transition: transform 0.4s ease-in-out;
  box-shadow: 0 0 0 0 rgb(0 0 0 / 20%), 0 2px 2px 0 rgb(0 0 0 / 14%), 0 0 0 0 rgb(0 0 0 / 12%);
  z-index: 10;
}

.header.header_view {
  position: fixed;
  transform: translateY(0);
  transition: transform 0.4s ease-in-out;
  box-shadow: 0 0 0 0 rgb(0 0 0 / 20%), 0 2px 2px 0 rgb(0 0 0 / 14%), 0 0 0 0 rgb(0 0 0 / 12%);
  z-index: 10;
}

.header {
  position: absolute;
  display: flex;
  flex-direction: row;
  height: 90px;
  width: 100%;
  background-color: $white;

  @include breakpoint($xl) {
    margin: 0 auto;
    height: 100px;
    padding: 0 calc(50% - 600px);
  }

  &__menu {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0 30px;

    @include breakpoint($md-and-sm) {
      display: none;
    }

    &__link {
      @include t4;

      padding: 20px;
      position: relative;
      height: auto;
      display: flex;
      align-items: center;

      a {
        color: $black;
        cursor: pointer;

        &:hover {
          color: $primary;
          text-decoration: none;
        }
      }

      &__active {
        font-weight: 600;

        a {
          color: $black;
        }
      }
    }
  }

  &_fixed {
    position: fixed;
    z-index: 10;
  }

  &__menuOpen {

    svg {

      path {
        transition: transform 0.25s ease-in-out;
      }

      path:nth-of-type(1) {
        transform: rotate(45deg);
        transform-origin: 11px 0;
      }

      path:nth-of-type(2) {
        display: none;
      }

      path:nth-of-type(3) {
        transform: rotate(-45deg);
        transform-origin: 6px 5px;
      }
    }
  }

  &__logoWrapper {
    display: flex;
  }

  &__burgerMenuButton.header__menuOpen {

    svg {

      path:nth-of-type(1) {
        transform: rotate(45deg);
        transform-origin: 11px 0;
      }

      path:nth-of-type(2) {
        opacity: 0;
      }

      path:nth-of-type(3) {
        transform: rotate(-45deg);
        transform-origin: 6px 5px;
      }
    }
  }

  &__burgerMenuButton {
    padding: 30px 20px;

    @include breakpoint($xl) {
      display: none;
    }

    svg {

      path {
        transition: transform 0.25s ease-in-out, opacity 0.25s ease-in-out;
      }

      path:nth-of-type(1) {
        transform: rotate(0);
        transform-origin: 11px 0;
      }

      path:nth-of-type(2) {
        opacity: 1;
      }

      path:nth-of-type(3) {
        transform: rotate(0);
        transform-origin: 6px 5px;
      }
    }
  }

  &__logoLink {
    display: flex;
    align-items: center;
  }

  &__mainLink {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 30%;

    @include breakpoint($md-and-sm) {
      width: 26%;
    }
  }

  &__liveLink {
    position: absolute;
    top: 0;
    right: 0;
    left: 30%;
    height: 100%;

    @include breakpoint($md-and-sm) {
      left: 26%;
    }
  }

  &__logo {
    height: 60px;
    width: 224px;
    background: url('/images/logo.svg') no-repeat;

    @include breakpoint($md) {
      height: 50px;
      background-size: auto 50px;
    }

    @include breakpoint($sm) {
      width: 156px;
      height: 44px;
      background-size: auto 44px;
    }

    &_dobro {
      background: url('/images/logo_dobro.svg') no-repeat;

      @include breakpoint($md-and-sm) {
        background: url('/images/logo_dobro.svg') no-repeat;
      }
    }
  }

  &__logoLive {
    height: 60px;
    width: 224px;
    position: relative;
    background: url('/images/logoLive.svg');

    @include breakpoint($md-and-sm) {
      height: 50px;
      width: 217px;
      background: url('/images/small-logoLive.svg');
    }
  }

  &__buttons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 100%;
    margin-left: auto;
  }

  &__live {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 50px;
    color: $black;

    @include p3;

    text-align: center;
    z-index: 1;

    @include breakpoint($md-and-sm) {
      display: none;
    }

    .point {
      position: absolute;
      left: 25px;
      top: 42px;
      width: 4px;
      height: 4px;
      background-color: $button-bg-default;
      border-radius: 50%;
      z-index: 2;
    }
  }

  &__search {
    height: 100%;
    width: 50px;
    background-color: $header-button-search-bg-default;

    svg {

      path {
        fill: $header-button-search-icon-default;
      }
    }

    @include breakpoint($md-and-sm) {
      width: 40px;
    }

    &:hover {
      background-color: $header-button-search-bg-hover;

      svg {

        path {
          fill: $header-button-search-icon-hover;
        }
      }
    }
  }

  &__profile {
    @include breakpoint($md-and-sm) {
      margin-right: 10px;
    }
  }

  &__profileAuth {

    &__label {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 25px;
      height: 25px;
      border: 2px solid $header-button-profile-icon-default;
      border-radius: 50%;

      @include t3;

      color: $header-button-profile-icon-default;
      text-transform: uppercase;
    }

    &:hover > & {

      &__label {
        color: $header-button-profile-icon-hover;
        border-color: $header-button-profile-icon-hover;
      }
    }

    &__avatar {
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      position: relative;

      &:hover {

        &:before {
          content: '';
          display: block;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          z-index: 1;
          background: linear-gradient(0deg, rgb(255 255 255 / 20%), rgb(255 255 255 / 20%));
        }
      }

      img {
        width: 32px;
        height: 32px;
        border-radius: 50%;
      }
    }

    @include breakpoint($md-and-sm) {
      margin-right: 16px;
    }
  }

  &__profile,
  &__profileAuth {
    height: 100%;
    width: 50px;
    text-align: -moz-center;
    text-align: -webkit-center;
    background-color: $header-button-profile-bg-default;

    @include breakpoint($md-and-sm) {
      width: 40px;
    }

    svg {

      path {
        fill: $header-button-profile-icon-default;
      }
    }

    &:hover {
      background-color: $header-button-profile-bg-hover;

      svg {

        path {
          fill: $header-button-profile-icon-hover;
        }
      }
    }
  }
}
