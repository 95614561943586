:global(body.region__bel) {

  .wrapper {
    padding: 0 40px;

    @include breakpoint($xl) {
      height: 360px;
    }
  }

  .form {
    margin-top: 80px;
    margin-bottom: 80px;

    @include breakpoint($sm) {
      margin-top: 40px;
      margin-bottom: 40px;
    }
  }

  .title {
    @if mixin-exists(accent3) {
      @include accent3;

      @include breakpoint($sm) {
        @include accent4;
      }
    }

    color: $secondary;
    margin-bottom: 10px;

    @include breakpoint($sm) {
      margin-bottom: 6px;
    }
  }

  .subTitle {
    margin-bottom: 30px;

    @include breakpoint($sm) {
      margin-bottom: 20px;
    }
  }

  .logo {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 40%;
    height: 100%;
    background: url('/images/big-logo.svg') no-repeat bottom right;
  }

  @include breakpoint($md-and-sm) {

    .form {
      width: 100%;
    }
  }

  .modal {

    .wrapper {
      padding: 0;
      height: auto;
    }

    .form {
      margin-top: 0;
      margin-bottom: 0;
    }

    .title {
      font-size: 29px;
      line-height: 33px;
      margin-bottom: 16px;

      @include breakpoint($sm) {
        font-size: 24px;
        line-height: 28px;
        margin-bottom: 8px;
      }
    }

    .subTitle {
      margin-bottom: 24px;

      @include breakpoint($sm) {
        margin-bottom: 12px;
      }

      &_noMargin {
        margin-bottom: 0;
      }
    }
  }
}
