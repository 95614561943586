
      @import "regions/kursk/styles/themes/sakhalin/vars.scss";
      @import "regions/kursk/styles/common_vars.scss";
      @import "regions/kursk/styles/themes/sakhalin/fonts.scss";
      @import "regions/kursk/styles/themes/sakhalin/typography.scss";
      @import "regions/kursk/styles/text.scss";
    
@import 'ScrollButton.bel.module';

.button {
  position: fixed;
  right: 20px;
  bottom: 50px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: $scroll-up-button-default;
  transition: $card-bg-transition;
  z-index: 2;

  @include breakpoint($xl) {
    left: calc(50% + 640px);
  }

  @include breakpoint($sm) {
    bottom: 90px;
  }

  @media (hover: hover) {

    &:hover {
      background-color: $scroll-up-button-hover;
    }
  }

  &:active {
    background-color: $scroll-up-button-hover;
  }

  svg {
    transform: rotate(180deg);
    margin-bottom: 2px;

    path {
      fill: $white;
      transition: all 0.3s ease;
    }
  }
}

.hidden {
  display: none;
}
