:global(body.region__bel) {

  .logo {
    margin-top: 26px;
    margin-bottom: 26px;
    height: auto;

    @include breakpoint($sm) {
      margin-top: 20px;
      margin-bottom: 16px;

      > img {
        width: 100%;
        max-width: 288px;
      }
    }
  }
}
