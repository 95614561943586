:global(body.region__bel) {

  .button {
    background-color: $white;
    box-shadow: 0 1px 6px rgb(0 75 75 / 10%);
    padding-top: 5px;

    svg {
      margin-bottom: 0;

      path {
        fill: $black;
      }
    }
  }

  @media (hover: hover) {

    .button:hover {
      background-color: $primary-hover;

      svg {

        path {
          fill: $white;
        }
      }
    }
  }

  .button:active {
    background-color: $primary-hover;

    svg {

      path {
        fill: $white;
      }
    }
  }
}
