
      @import "regions/kursk/styles/themes/sakhalin/vars.scss";
      @import "regions/kursk/styles/common_vars.scss";
      @import "regions/kursk/styles/themes/sakhalin/fonts.scss";
      @import "regions/kursk/styles/themes/sakhalin/typography.scss";
      @import "regions/kursk/styles/text.scss";
    
.wrapper {
  display: flex;
  flex-direction: row;
}

/* для элемента input c type="checkbox" */

.checkbox {
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  padding-left: 0;

  & > input {
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
    display: none;
  }

  input[disabled] + .checkmark {
    color: $black-30;
    background-color: $black-5;
  }

  input[disabled] ~ .content {
    color: $black-30;
  }

  .checkmark {
    height: 16px;
    width: 16px;
    min-height: 16px;
    min-width: 16px;
    background-color: $white;
    border: 1px solid $border;
    position: relative;
    transition: border-color 0.3s ease, background-color 0.3s ease;

    &_forFilter {
      position: initial;
      margin-right: 10px;
    }
  }

  & > input:checked ~ .checkmark {
    background-color: $primary;
    border-color: $primary;
  }

  .checkmark:after {
    box-sizing: content-box;
    content: '';
    position: absolute;
    display: block;
    opacity: 0;
    top: 0;
    left: 5px;
    width: 3px;
    height: 9px;
    border: solid $white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
    transition: opacity 0.2s ease;

    &_forFilter {
      position: initial;
      margin-right: 10px;
    }
  }

  & > input:checked + .checkmark:after {
    opacity: 1;
  }

  & > .content {
    display: inline-flex;
    align-items: center;
    user-select: none;
    margin-left: 10px;
  }

  .error {
    border: 1px solid $error-border;
  }

  &:hover {

    .checkmark {
      border-color: $primary;
    }
  }
}

.error_text {
  font-size: 12px;
  text-align: left;
  color: $error-border;
  margin-top: 5px;

  @include breakpoint($xl) {
    position: absolute;
  }
}
