
      @import "regions/kursk/styles/themes/sakhalin/vars.scss";
      @import "regions/kursk/styles/common_vars.scss";
      @import "regions/kursk/styles/themes/sakhalin/fonts.scss";
      @import "regions/kursk/styles/themes/sakhalin/typography.scss";
      @import "regions/kursk/styles/text.scss";
    
.cookies {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100vw;
  display: flex;
  background: $white;
  opacity: 1;
  box-shadow: 0 0 0 0 rgb(0 0 0 / 20%), 0 -2px 2px 0 rgb(0 0 0 / 14%), 0 0 0 0 rgb(0 0 0 / 12%);
  z-index: 100;
  animation: wrap-up 0.9s ease-in;
}

.cookieWrap {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 30px 0;
  width: 100%;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;

  @include breakpoint($md) {
    padding: 30px 20px;
  }

  @include breakpoint($sm) {
    padding: 30px 20px;
    flex-direction: column;
  }
}

.cookieContent {
  padding-right: 20px;

  @include breakpoint($sm) {
    padding-right: 0;
  }
}

.cookieTitle {
  @include h15Mobile;

  margin-bottom: 10px;
}

.cookieAction {
  display: flex;
  align-items: center;
  justify-content: center;

  @include breakpoint($sm) {
    margin-top: 20px;
    width: 187px;
  }
}

.cookieSub {
  @include t3;
}

.cookieReedMore {
  margin-left: 6px;
}

@keyframes wrap-up {

  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
