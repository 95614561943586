
      @import "regions/kursk/styles/themes/sakhalin/vars.scss";
      @import "regions/kursk/styles/common_vars.scss";
      @import "regions/kursk/styles/themes/sakhalin/fonts.scss";
      @import "regions/kursk/styles/themes/sakhalin/typography.scss";
      @import "regions/kursk/styles/text.scss";
    
.header {
  @include h2;

  text-align: center;

  &_title {
    margin-bottom: 40px;
  }

  &_subtitle {
    @include h3;

    padding-top: 15px;
    margin-bottom: 30px;
  }
}

.backArrow {
  position: absolute;
  left: 20px;
  top: 20px;
  cursor: pointer;
}

.phoneWrap {
  margin-bottom: 40px;
}

.tabSection {
  display: flex;
  align-items: center;
  margin-bottom: 40px;

  .tabEmail,
  .tabTel {
    @include h15Mobile;

    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
    flex-basis: 50%;
    text-transform: uppercase;
    color: $black;
    border-bottom: 1px solid $border;
    transition: 0.3s;

    span {
      padding-bottom: 6px;
      cursor: pointer;
    }
  }

  .tabActive {
    color: $primary;
    border-bottom: 1px solid $primary;
  }
}

.text {
  @include t1;

  text-align: center;
  vertical-align: top;

  &_error {
    @include t7;

    color: $error;
  }
}

.textBlock {
  padding-bottom: 30px;
}

.label {
  @include t4;

  color: $black-60;
  display: block;
  margin-bottom: 12px;
}

.input_wrapper {
  margin-bottom: 25px;
}

.signup {
  margin-top: 40px;
}

.signin {
  margin-top: 20px;
}

.radio_group {
  color: $black;
  display: flex;
  flex-direction: row;
  font-size: 18px;
  line-height: 22px;
  margin-bottom: 20px;
  padding: 4px 0;
}

.radio {
  cursor: pointer;

  .radio_circle {
    background-color: $white;
    border: 1px solid #c9c8c8;
    border-radius: 50%;
    box-sizing: border-box;
    display: inline-block;
    height: 12px;
    margin-right: 12px;
    vertical-align: middle;
    width: 12px;

    @include breakpoint($sm) {
      height: 12px;
      width: 12px;
    }
  }

  span {
    @include t4;

    margin-right: 20px;
  }

  .radio_button {
    display: none;

    &:checked + .radio_circle {
      background-color: $white;
      border: 3px solid $primary;
      border-radius: 50%;
      box-sizing: border-box;
      display: inline-block;
      height: 12px;
      margin-right: 10px;
      vertical-align: middle;
      width: 12px;

      @include breakpoint($sm) {
        height: 12px;
        width: 12px;
      }
    }
  }
}
