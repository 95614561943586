
      @import "regions/kursk/styles/themes/sakhalin/vars.scss";
      @import "regions/kursk/styles/common_vars.scss";
      @import "regions/kursk/styles/themes/sakhalin/fonts.scss";
      @import "regions/kursk/styles/themes/sakhalin/typography.scss";
      @import "regions/kursk/styles/text.scss";
    
.wrapper {
  position: relative;
  width: 650px;

  .close {
    cursor: pointer;
    position: absolute;
    top: -45px;
    right: 0;

    &_mobile {
      display: none;

      @include breakpoint($sm) {
        display: block;
      }
    }
  }

  @include breakpoint($sm) {
    margin: 0 auto;
    padding: 0;
    width: 100%;

    svg {
      display: none;
    }
  }
}

.modal {
  @include breakpoint($sm) {
    background-color: rgba($black, 0.4);
    padding: 0 16px;
    display: flex;
    align-items: center;
  }
}
