
      @import "regions/kursk/styles/themes/sakhalin/vars.scss";
      @import "regions/kursk/styles/common_vars.scss";
      @import "regions/kursk/styles/themes/sakhalin/fonts.scss";
      @import "regions/kursk/styles/themes/sakhalin/typography.scss";
      @import "regions/kursk/styles/text.scss";
    
.header {
  @include h2;

  text-align: center;
  margin-bottom: 10px;
}

.text {
  @include t1;

  text-align: center;
  vertical-align: top;
}
