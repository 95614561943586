
      @import "regions/kursk/styles/themes/sakhalin/vars.scss";
      @import "regions/kursk/styles/common_vars.scss";
      @import "regions/kursk/styles/themes/sakhalin/fonts.scss";
      @import "regions/kursk/styles/themes/sakhalin/typography.scss";
      @import "regions/kursk/styles/text.scss";
    
.wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
}

.area {
  color: $black;
  border: 1px solid $border;
  padding: 10px 16px;

  @include p3;

  font-family: inherit;
  width: 100%;
  resize: none;
  height: 220px;

  &:focus {
    border: 1px solid $input-border-focus;
  }

  &__error {
    border: 1px solid $error-border;

    &:focus {
      border: 1px solid $error-border;
    }
  }
}

.errorText {
  @include t7;

  color: $error;
  margin-top: 5px;

  @include breakpoint($xl) {
    position: absolute;
    top: 100%;
  }
}

.input + .errorText {
  margin-bottom: 5px;
  color: $error;
}
