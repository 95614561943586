
      @import "regions/kursk/styles/themes/sakhalin/vars.scss";
      @import "regions/kursk/styles/common_vars.scss";
      @import "regions/kursk/styles/themes/sakhalin/fonts.scss";
      @import "regions/kursk/styles/themes/sakhalin/typography.scss";
      @import "regions/kursk/styles/text.scss";
    
.footer__wrapper {
  display: flex;
  flex-direction: column;
  background-color: $footer-bg;
  width: 100%;

  a {
    color: $footer-link-text-default;
  }

  a:hover {
    text-decoration: none;
    color: $footer-link-text-hover;
  }

  @include breakpoint($xl) {
    width: $xl;
    margin: 0 auto;
  }
}

.footer__copyright {
  @include t4;

  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-top: 1px solid $footer-border;
  height: 60px;
  color: $footer-text-main;

  @include breakpoint($sm) {
    height: 77px;
    padding: 20px 20px 25px;
  }
}

.footer__row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 80px 0 73px;

  @include breakpoint($xl) {
    width: $xl;
    margin: 0 auto;
  }

  @include breakpoint($sm) {
    flex-direction: column;
    padding: 45px 0;
    height: 704px;
  }

  @include breakpoint($md) {
    padding: 60px 0;
  }
}

.footer__logo_wrap {
  display: flex;
  margin-bottom: 4px;
}

.footer__logo {
  height: 50px;
}

.footer__section {
  display: flex;
  flex-direction: column;
  color: $footer-text-main;

  @include p3;

  @include breakpoint($sm) {
    margin: 0 auto;
  }

  @include breakpoint($md-and-sm) {

    &:nth-child(3) {
      border-top: 1px solid $footer-border;
      padding: 45px 0 10px;
      margin: 0;
    }
  }

  @include breakpoint($md) {

    &:nth-child(3) {
      width: 100vw;
      padding: 45px 0 0;
    }

    &:nth-child(1),
    &:nth-child(2) {
      padding: 0 60px 45px;
    }
  }
}

.footer__info {
  margin: 0 auto;
}

.footer__menuLink {
  padding: 5px 0;
}

.footer__title {
  color: $footer-text-secondary;
}

.footer__phone {
  font-size: 18px;
  font-weight: 700;
  line-height: 25px;
  color: $footer-text-main;
}

.footer__callTime {
  font-size: 12px;
  line-height: 16px;
  color: $footer-text-secondary;
}

.footer__mail {
  font-size: 14px;
  line-height: 20px;
  color: $footer-text-main;
  margin-top: 10px;
}

.footer__writeUs {
  margin-top: 10px;
}

.footer__faq {
  margin-top: 10px;
}

.footer__pushkincard {
  margin-top: 10px;
}

.footer__login {
  margin-top: 20px;
}

.footer__socials {
  display: flex;
  padding: 20px 0;
  width: 185px;

  a {
    margin-right: 10px;
  }

  @include breakpoint($sm) {
    margin: 0 auto;
  }
}

.footer__socialIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid $footer-border;
  border-radius: 50%;
  width: 35px;
  height: 35px;

  svg path {
    fill: $footer-text-main;
  }

  &:hover {
    background-color: $footer-link-text-hover;
    cursor: pointer;

    svg path {
      fill: $footer-bg;
    }
  }
}

.footer__titleText {
  white-space: pre;
}

.copyright {
  display: flex;
  flex-flow: column nowrap;
  gap: 10px;
  align-items: center;
  justify-content: center;
  background-color: $footer-bg;
  border-top: 1px solid $footer-border;
  padding: 20px 60px;

  &__svg {
    color: $footer-text-main;
  }

  &__text {
    @include t4;

    color: $footer-link-text-default;
    text-align: center;
  }
}
