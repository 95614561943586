
      @import "regions/kursk/styles/themes/sakhalin/vars.scss";
      @import "regions/kursk/styles/common_vars.scss";
      @import "regions/kursk/styles/themes/sakhalin/fonts.scss";
      @import "regions/kursk/styles/themes/sakhalin/typography.scss";
      @import "regions/kursk/styles/text.scss";
    
body ~ .layout {
  overflow: hidden;
}

.hidden {
  display: none;
}

.open {
  background-color: $white-hover;
  z-index: 2;
}

.layout {
  display: flex;
  align-items: center;
  flex-direction: column;
  min-height: 100vh;
  background-color: $layout-bg;

  @include breakpoint($md-and-sm) {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.content {
  max-width: $xl;
  width: 100%;
  margin: 100px auto 0;
  flex-grow: 1;

  @include breakpoint($md-and-sm) {
    margin-top: 90px;
  }
}

.footer {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 0;
  border-top: 1px solid $border;
  background-color: $footer-bg;

  @include breakpoint($md-and-sm) {
    width: calc(100% + 40px);
  }

  a {

    &:hover {
      text-decoration: none;
    }
  }

  &__live {
    background-color: $white;
  }
}

.header {

  &__menu {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 30px;

    @include breakpoint($md-and-sm) {
      display: none;
    }

    &__link {
      @include menuHeader;

      @include breakpoint($md) {
        @include t3;
      }

      @include breakpoint($sm) {
        @include t4;
      }

      padding: 10px 20px;
      position: relative;
      height: 100%;
      display: flex;
      align-items: center;

      &_title {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      ul a {
        white-space: nowrap;
      }

      a {
        color: $black;
        cursor: pointer;

        &:hover {
          color: $primary;
          text-decoration: none;
        }
      }

      &__active {
        font-weight: 600;

        a {
          color: $black;
        }
      }

      @include breakpoint($md) {
        @include t2;

        a {
          @include t2;
        }

        ul a {
          @include t2;
        }
      }

      @include breakpoint($sm) {
        @include t2;

        a {
          @include t2;
        }

        ul a {
          @include t2;
        }
      }
    }
  }
}

.header__drawer {
  top: 90px;
  left: 0;

  // 90px - header height
  height: calc(100vh - 90px);
  width: 375px;
  position: fixed;
  background-color: $white;
  z-index: 3;
  transition: transform 0.3s ease-in-out;
  transform: translateX(-100%);

  .header__bvi {
    @include b2;

    display: block;
    width: 100%;
    height: 60px;
    padding: 20px;
    text-align: start;
    color: $white;
    background-color: $primary;

    span {
      @include b2;
    }
  }

  &.open {
    transform: translateX(0);
  }

  @include breakpoint($sm) {
    width: 100%;
  }

  body:global(.bvi-active) & {
    top: 280px;
  }
}

.header__drawerWrap {
  height: 100%;
  overflow: auto;
}

.header__drawerInner {
  display: flex;
  flex-direction: column;
  padding-bottom: 160px;

  .header__menu__link {
    padding: 20px 0 0 20px;
  }
}

.header__drawer.open:after {
  content: '';
  position: fixed;
  top: 0;
  left: 375px;
  width: 100vw;
  height: 100vh;
  z-index: 0;
  background: $black-40;

  @include breakpoint($sm) {
    display: none;
  }
}
