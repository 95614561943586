:global(body.region__bel) {

  .wrapper {
    padding: 60px 40px;
    background-color: $white;
    height: initial;
  }

  .form {
    margin-top: 0;
  }

  .title {
    @if mixin-exists(accent2) {
      @include accent2;
    }

    color: $secondary;
    white-space: nowrap;

    @include breakpoint($md) {
      @if mixin-exists(accent3) {
        @include accent3;
      }
    }

    @include breakpoint($sm) {
      @if mixin-exists(accent4) {
        @include accent4;
      }

      white-space: break-spaces;
    }
  }

  .titleModal {
    @include h2;

    margin-bottom: 4px;
  }

  .logo {
    align-items: end;
    margin-bottom: -60px;
    margin-right: -40px;
  }
}
