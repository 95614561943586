
      @import "regions/kursk/styles/themes/sakhalin/vars.scss";
      @import "regions/kursk/styles/common_vars.scss";
      @import "regions/kursk/styles/themes/sakhalin/fonts.scss";
      @import "regions/kursk/styles/themes/sakhalin/typography.scss";
      @import "regions/kursk/styles/text.scss";
    
.header {
  @include h2;

  text-align: center;
  margin-bottom: 10px;
}

.backArrow {
  position: absolute;
  left: 20px;
  top: 20px;
  cursor: pointer;
}

.text {
  @include t1;

  text-align: center;
  vertical-align: top;

  &_error {
    @include t7;

    color: $error-border;
  }
}

.textBlock {
  padding-bottom: 30px;
}

.label {
  @include t4;

  color: $black-60;
  display: block;
  margin-bottom: 12px;
}

.input_wrapper {
  margin-bottom: 40px;
}

.marginBottom {
  margin-bottom: 20px;
}
