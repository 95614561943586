
      @import "regions/kursk/styles/themes/sakhalin/vars.scss";
      @import "regions/kursk/styles/common_vars.scss";
      @import "regions/kursk/styles/themes/sakhalin/fonts.scss";
      @import "regions/kursk/styles/themes/sakhalin/typography.scss";
      @import "regions/kursk/styles/text.scss";
    
.footer__wrapper {
  display: flex;
  flex-direction: column;
  background-color: $footer-bg;
  width: 100%;
  padding: 60px 0;

  @include breakpoint($md-and-sm) {
    padding: 60px 0;
  }

  a {
    color: $footer-link-text-default;
  }

  a:hover {
    text-decoration: none;
    color: $footer-link-text-hover;
  }
}

.footer__row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @include breakpoint($xl) {
    width: $xl;
    margin: 0 auto;
  }

  @include breakpoint($sm) {
    flex-direction: column;
    gap: 60px;
  }
}

.footer__live {
  border-top: 1px solid $border;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  padding: 80px 0;

  .footer .footer__section:nth-child(2) {
    display: none;
  }

  @include breakpoint($sm) {
    border: none;
    flex-direction: column;
    align-items: stretch;
    padding: 45px 0;

    & > a {
      align-self: center;
      margin-bottom: 45px;
    }
  }

  @include breakpoint($md) {
    border: none;
    flex-direction: column;
    align-items: stretch;
    padding: 60px 0;

    & > a {
      align-self: center;
      margin-bottom: 60px;
    }
  }
}

.footer__logo {
  height: 170px;
  width: 100%;
  background: url('/images/footer-logo.svg') no-repeat center;
}

.footer__section {
  display: flex;
  flex-direction: column;
  color: $footer-link-text-default;

  @include p3;

  padding: 0;

  @include breakpoint($md) {
    padding: 0 20px;
  }

  @include breakpoint($sm) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 0 auto;
    padding: 0;

    &:nth-child(2) {
      order: 1;
    }

    &:nth-child(1) {
      order: 2;
    }

    &:nth-child(3) {
      order: 3;
    }
  }
}

.footer__section_center {
  width: 235px;
  margin-left: auto;
  margin-right: auto;
  margin-top: -240px;
}

.footer__info {
  margin: 0 auto;
}

.footer__menuLink {
  padding: 5px 0;
}

.footer__title {
  color: $footer-link-text-default;
  margin-bottom: 5px;
}

.footer__phone {

  a {
    @include number;

    color: $footer-text-main;
  }
}

.footer__callTime {
  font-size: 12px;
  line-height: 16px;
  color: $footer-link-text-default;
}

.footer__mail {
  font-size: 14px;
  line-height: 20px;
  color: $footer-text-main;
  margin-top: 10px;
}

.footer__writeUs {
  margin-top: 10px;
}

.footer__faq {
  margin-top: 10px;
}

.footer__pushkincard {
  margin-top: 10px;
}

.footer__login {
  margin-top: 20px;

  @include breakpoint($sm) {
    margin-top: 10px;
  }
}

.footer__socials {
  display: flex;
  padding: 30px 0 0;
  width: 100%;
  align-items: center;
  gap: 15px;

  a {
    margin: 0 auto;
  }

  @include breakpoint($sm) {
    margin: 0 auto;
  }
}

.footer__socialIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid $footer-border;
  border-radius: 50%;
  width: 35px;
  height: 35px;

  svg path {
    fill: $white-80;
  }

  &:hover {
    background-color: $footer-link-text-default;
    cursor: pointer;

    svg path {
      fill: $black;
    }
  }
}

.copyright {
  display: flex;
  flex-flow: column nowrap;
  gap: 10px;
  align-items: center;
  justify-content: center;
  background-color: $footer-bg;
  border-top: 1px solid $footer-border;
  padding: 20px 60px;

  &__svg {
    color: $footer-text-main;
  }

  &__text {
    @include t4;

    color: $footer-link-text-default;
    text-align: center;
  }
}
